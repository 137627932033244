<template>
  <div>
    <b-card class="invoice-preview-card">
      <div class="d-flex justify-content-between flex-wrap">
        <h2 class="my-auto">
          {{ $t('customer_information.faq.title') }} <br></h2>
        <b-button
          variant="primary"
          class="d-flex align-items-center"
          @click="$router.push({ name: 'configs-faqs-create' })"
        >
          <i class="las la-plus-circle mr-25 la-lg" />
          <!-- Nouvelle fiche contact -->
          {{ $t('customer_information.faq.create_button') }}
        </b-button>
      </div>
    </b-card>
    <div>
      <b-card>
        <b-card-body>
          <b-form @submit.prevent>
            <b-row>
              <b-col cols="6">
                <b-form-group
                  :label="$t('customer_information.faq.filter_label_question')"
                  label-for="nom"
                  label-cols-md="12"
                >
                  <b-form-input
                    id="nom"
                    v-model="title"
                    :placeholder="$t('customer_information.faq.filter_input_question_placeholder')"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  :label="$t('customer_information.information_content.create_input_sector')"
                  label-cols-md="12"
                  label-for="mots-cles"
                >
                  <v-select
                    id="roles"
                    v-model="faq_section_id"
                    :placeholder="$t('customer_information.information_content.create_input_sector_placeholder')"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :reduce="(option) => option.id"
                    :options="listSecteurs"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-button
                  class="float-right"
                  variant="warning"
                  @click="gsearch()"
                >
                  <feather-icon
                    v-if="!isSearchingUsers"
                    icon="SearchIcon"
                    class="mr-25"
                  />
                  <b-spinner
                    v-else
                    small
                    class="mr-25"
                  />
                  <span>{{ $t('button.search') }}</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card-body>
      </b-card>
    </div>

    <b-card>
      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="foundQuestions"
        responsive
        :fields="tableColumns"
        hover
        primary-key="id"
        show-empty
        :empty-text="$t('table_columns.empty_text')"
        :busy="isSearchingUsers"
      >
        <!-- Custom Header -->
        <template #head()="{ label, field: { key, sortable } }">
          {{ $t(label) }}
        </template>

        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>

        <!-- Column: User -->
        <template #cell(title)="data">
          <div class="">
            <h6 class="w-100">
              {{ reduct(data.item.title, 60) }}
            </h6>
          </div>
        </template>
        <template #cell(faq_section)="data">
          <div class="">
            <b-badge
              v-if="data.item.faq_section == null"
              pill
              variant="light-primary"
            >
              Autres
            </b-badge>
            <b-badge
              v-else
              variant="light-primary"
            >
              {{ data.item.faq_section.title }}
            </b-badge>
          </div>
        </template>

        <template #cell(actions)="data">
          <div class="d-flex justify-content-center">
            <div class="my-auto">
              <b-form-checkbox
                v-b-tooltip.hover.:top="'activer'"
                :checked="data.item.can_show"
                class="custom-control-green"
                switch
                @change="() => {
                  changeStatus(data.item);
                }
                "
              />
            </div>
            <div class="d-flex align-items-center">
              <b-dropdown
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                split
                variant="primary"
                @click="details(data.item.id)"
              >
                <template #button-content>
                  <feather-icon icon="EyeIcon" /> {{ $t('button.see') }}
                </template>
                <b-dropdown-item
                  :to="{
                    name: 'configs-faqs-edit',
                    params: { id: data.item.id },
                  }"
                >
                  {{ $t('button.edit') }}
                </b-dropdown-item>
              </b-dropdown>

              <b-button
                variant="danger"
                class="ml-2"
                @click="wouldDelete(data.item)"
              >
                <i class="las la-trash-alt la-lg" /> {{ $t('button.remove') }}</b-button>
            </div>
          </div>
        </template>
      </b-table>
      <PaginationComponent
        :table-data="foundQuestions"
        :per-page="paginationData.perPage"
        :total-rows="paginationData.total"
        @change="onPaginationChange"
      />
    </b-card>
    <b-modal
      id="modal-delete"
      ref="modal-delete"
      :title="$t('modal.faq.delete_title')"
      ok-only
      ok-variant="danger"
      modal-class="modal-danger"
      :ok-title="$t('button.remove')"
      no-close-on-backdrop
      @ok="remove"
    >
      <b-card-text>
        <h5>{{ $t('modal.faq.delete_text') }}</h5>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCollapse,
  BCard,
  BCardText,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BForm,
  BFormInput,
  BFormGroup,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  VBToggle,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BPagination,
  BFormCheckbox,
  VBTooltip,
  VBModal,
  BSpinner,
  BFormTags,
  BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import { mapActions } from 'vuex'

import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'
import Ripple from 'vue-ripple-directive'
import PaginationComponent from '@/components/PaginationComponent.vue'
import faqStoreModule from '@/store/faq'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import localstorageService from '@/services/localstorage/localstorage.service'

export default {
  components: {
    BFormCheckbox,
    BCard,
    BCardText,
    BCardHeader,
    BCardBody,
    BRow,
    BForm,
    BFormInput,
    BCol,
    BFormGroup,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCollapse,
    BDropdownDivider,
    BSpinner,
    BFormTags,
    BModal,
    vSelect,
    PaginationComponent,
    VBModal,
  },

  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
    Ripple,
  },
  props: {},

  data() {
    return {
      title: null,
      selectedRoles: [],
      faq_section_id: null,
      isSearchingUsers: false,
      foundQuestions: [],
      showablefoundQuestions: [],
      listSecteurs: [],
      tableColumns: [
        { key: 'title', label: 'table_columns.question', sortable: true },
        {
          key: 'faq_section',
          label: 'table_columns.sector',
          sortable: true,
        },
        {
          key: 'actions',
          label: 'table_columns.actions',
        },
      ],

      filterOptions: {
        title: null,
        faq_section_id: [],
      },

      paginationData: {
        currentPage: 1,
        total: 0,
        perPage: 10,
        metaData: { from: 0, to: 0 },
      },
      optionsPerPage: [10, 15, 30, 100, 200, 500],
      currentId: null,

      isFetchingSecteur: false,
    }
  },
  computed: {},
  watch: {
    $route: {
      immediate: true,
      handler(val, old) {
        this.gsearch()
      },
    },
    perPage: {
      immediate: true,
      handler(val, old) {
        if (this.foundQuestions && this.foundQuestions[val - 1]) {
          this.foundQuestions = this.customCloneDeep(
            this.foundQuestions.slice(0, val - 1),
          )
        }
      },
    },
    'paginationData.currentPage': {
      handler(val, old) {
        this.gsearch()
      },
    },
    'paginationData.perPage': {
      handler(val, old) {
        this.gsearch()
      },
    },
  },

  setup() {
    const requiredStoreModules = [{ path: 'faqs', module: faqStoreModule }]
    // Register module
    registerStoreModule(requiredStoreModules)

    return { requiredStoreModules }
  },

  // beforeDestroy() {
  //   unregisterStoreModule(this.requiredStoreModules)
  // },
  mounted() {
    this.fetchSecteurs()
  },

  methods: {
    ...mapActions('faqs', {
      action_searchFaq: 'searchFaq',
      action_deleteFaq: 'deleteFaq',
      action_fetchFaqSections: 'fetchFaqSections',
      action_updateStatusFaq: 'updateStatusFaq',
    }),
    fetchSecteurs() {
      this.isFetchingSecteur = true
      this.action_fetchFaqSections()
        .then(response => {
          this.isFetchingSecteur = false
          this.listSecteurs = response.data.data
          console.log('this.formload: ', this.listSecteurs)
        })
        .catch(error => {
          this.isFetchingSecteur = false
        })
    },
    /*  profilLink(user) {
      console.log(user);
      const media =
        user.media.length > 0
          ? user.media.find((objet) => objet.collection_name === "avatar")
          : null;
      return media ? media.preview_url : null;
    }, */
    changeStatus(item) {
      item.can_show = !item.can_show
      this.action_updateStatusFaq({
        id: item.id,
        data: { can_show: item.can_show },
      })
        .then(res => {
          if (res.status === 202) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Opération réussie',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch(err => {
          this.isSearchingUsers = true
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || 'Une erreur est survenue',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    reduct(chaine, nombreCaracteres) {
      if (chaine.length <= nombreCaracteres) {
        return chaine
      }
      const premiersCaracteres = `${chaine.substring(0, nombreCaracteres)}...`
      return premiersCaracteres
    },
    gsearch() {
      this.isSearchingUsers = true
      this.action_searchFaq({
        title: this.title,
        faq_section_id: this.faq_section_id,
        // params for filter by pagination
        page: this.paginationData.currentPage,
        per_page: this.paginationData.perPage,
      })
        .then(response => {
          // console.log("response: ", response.data);
          this.foundQuestions = response.data.data
          /* for (let i = 0; i < this.foundQuestions.length; i++) {
            if (this.foundQuestions[i].can_show) {
              this.showablefoundQuestions.push(this.foundQuestions[i])
            }
          }
          /* response.data.data.map(e => {
          console.log("faqs1", e)
          localstorageService.getUserData().roles.forEach(element => {
            console.log("faqs2", element)
            e.roles.map(i => {
              if (element.alias == i.alias) {
                this.foundQuestions.push(e)
              }
            })
          });
        }); */
          console.log('faqs', this.foundQuestions)
          this.isSearchingUsers = false
          // this.$emit('updateUsers', response.data.data)
          // --------------------------------------------------------------
          this.paginationData.total = response.data.meta.total || 0
          if (this.paginationData.metaData.from === 0) {
            this.paginationData.metaData.from += 1
          } else {
            this.paginationData.metaData.from = this.paginationData.perPage * this.paginationData.currentPage
              - this.paginationData.perPage
          }
          this.paginationData.metaData.to = this.paginationData.perPage * this.paginationData.currentPage
            - this.paginationData.perPage
            + response.data.meta.total
          // --------------------------------------------------------------
        })
        .catch(error => {
          this.isSearchingUsers = false
          console.log(error)
        })
    },
    wouldDelete(item) {
      this.currentId = item.id
      this.$refs['modal-delete'].show()
    },
    remove() {
      this.isSearchingUsers = true
      this.action_deleteFaq(this.currentId)
        .then(async res => {
          if (res.status === 204) {
            this.isSearchingUsers = false
            this.currentId = null
            this.gsearch()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Opération réussie',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch(err => {
          this.isSearchingUsers = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || 'Une erreur est survenue',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    onPaginationChange(val) {
      this.paginationData.currentPage = val.currentPage
      this.paginationData.perPage = val.perPage
    },
    details(id) {
      console.log('détails')
      this.$router.push({ name: 'configs-faqs-detail', params: { id } })
    },
    edit(id) {
      console.log('index: ', id)
      this.$router.push({ name: 'apps-users-edit', params: { id } })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-preview-wrapper {
  .row.invoice-preview {
    .col-md-8 {
      max-width: 100%;
      flex-grow: 1;
    }

    .invoice-preview-card {
      .card-body:nth-of-type(2) {
        .row {
          > .col-12 {
            max-width: 50% !important;
          }

          .col-12:nth-child(2) {
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            margin-top: 0 !important;
          }
        }
      }
    }
  }

  // Action Right Col
  .invoice-actions {
    display: none;
  }
}
</style>
